<template>
    <div class="container">
        <div
            class="inputList"
            v-if="type == 'Echo'"
            style="cursor: not-allowed"
        >
            <div class="company">Attachment</div>
            <div class="upload">
                <div><img src="../assets/pic.png" alt="" /></div>
                <div v-if="list.length > 0">
                    <div
                        v-for="(item, i) in list"
                        :key="i"
                        style="margin-bottom: 5px"
                        @click.stop="onClickPreview(item)"
                        class="pointer"
                    >
                        {{ item.fileName }}
                    </div>
                </div>
                <div v-else>No files uploaded</div>
            </div>
        </div>

        <div :id="element" class="inputList drop-area" v-else>
            <input
                type="file"
                ref="InputFile"
                multiple
                name="files"
                @change="uploadPic"
                :disabled="disabled"
            />
            <div class="company">Attachment</div>
            <div class="upload" v-if="list.length == 0">
                <div><img src="../assets/pic.png" alt="" /></div>
                <div class="text-normal">
                    Click or drag file to this area to upload
                </div>
                <div>
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other band files
                </div>
            </div>
            <div class="upload" v-else>
                <div><img src="../assets/pic.png" alt="" /></div>
                <div class="text-normal text-img-container flex-direction">
                    <div
                        v-for="(item, i) in list"
                        :key="i"
                        style="margin-bottom: 5px"
                    >
                        <div
                            style="cursor: pointer"
                            @click.stop="onClickPreview(item)"
                        >
                            {{ item.fileName }}
                            <img
                                v-if="!disabled"
                                class="img-del"
                                src="../assets/del.png"
                                @click.stop="onClickDel(i)"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other band files
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Attachments",
    props: {
        element: {
            type: String,
            default: "drop-area",
        },
        type: {
            type: String,
            default: "",
        },
        list: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    destroyed() {
        let dropArea = document.getElementById(this.element);
        if (dropArea) {
            dropArea.removeEventListener("drop", this.dropEvent);
        }
    },
    mounted() {
        if (this.type != "Echo") {
            let dropArea = document.getElementById(this.element);
            dropArea.addEventListener("drop", this.dropEvent, false);
            dropArea.addEventListener("dragleave", (e) => {
                e.stopPropagation();
                e.preventDefault();
            });
            dropArea.addEventListener("dragenter", (e) => {
                e.stopPropagation();
                e.preventDefault();
            });
            dropArea.addEventListener("dragover", (e) => {
                e.stopPropagation();
                e.preventDefault();
            });
        }
        // this.setImageStyle();
    },
    updated() {
        this.setImageStyle();
    },
    methods: {
        setImageStyle() {
            const container = document.querySelector(
                `#${this.element} .text-img-container`
            );
            if (container) {
                let height = 20;
                const childrens = container.children;
                for (let i = 0; i < childrens.length; i++) {
                    const element = childrens[i];
                    element.setAttribute("style", `top: ${i * 20}px`);
                    height += 20;
                }
                container.setAttribute("style", `min-height:${height}px`);
            }
        },
        onClickDel(index) {
            this.$confirm("Confirm the deletion").then(() => {
                this.$emit("dropDel", index);
            });
        },
        onClickPreview(items) {
            window.open(items.filePath);
        },
        dropEvent(e) {
            e.stopPropagation();
            e.preventDefault();
            let files = e.dataTransfer.files;
            this.uploadFile(files);
        },
        uploadPic() {
            let files = this.$refs.InputFile.files;
            this.uploadFile(files);
        },
        uploadFile(files) {
            for (let i = 0; i < files.length; i++) {
                let filename = files[i].name;
                let fileFormData = new FormData();
                fileFormData.append("file", files[i]);
                fileFormData.append("fileName", filename);
                this.$http.uploadFile(fileFormData).then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            message: "Upload succeeded",
                            type: "success",
                        });
                        let item = {
                            filePath: res.data,
                            fileName: filename,
                        };
                        this.$emit("dropEvent", item);
                    }
                });
            }
        },
    },
};
</script>

<style scoped>
.drop-area input {
    position: absolute;
    width: 100%;
    height: 75%;
    opacity: 0;
}
.img-del {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-left: 10px;
}
.text-normal {
    position: relative;
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-name {
    position: absolute;
    margin-top: 10px;
}
.pointer {
    cursor: pointer;
}
.flex-direction {
    flex-direction: column;
}
</style>
