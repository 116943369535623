<template>
    <div id="" class="container flex-start" style="flex-shrink: 1 !important">
        <div class="selectList">
            <el-select
                v-model="address.Country"
                placeholder="Please select"
                @change="changeSelect($event, 'Country')"
                :disabled="disabled"
            >
                <el-option
                    v-for="item in CountryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                >
                </el-option>
            </el-select>
        </div>
        <div class="selectList" v-if="CityList.length > 0">
            <el-select
                v-model="address.City"
                placeholder="Please select"
                @change="changeSelect($event, 'City')"
                :disabled="disabled"
            >
                <el-option
                    v-for="item in CityList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                >
                </el-option>
            </el-select>
        </div>
        <div class="selectList" v-if="DetailList.length > 0">
            <el-select
                v-model="address.Detail"
                placeholder="Please select"
                @change="changeSelect($event)"
                :disabled="disabled"
            >
                <el-option
                    v-for="item in DetailList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                >
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
export default {
    name: "Address",
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    Country: "",
                    City: "",
                    Detail: "",
                };
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            CountryList: [],
            CityList: [],
            DetailList: [],
            address: {
                Country: "",
                City: "",
                Detail: "",
            },
        };
    },
    created() {
        if (this.data) {
            this.address.Country = this.data.Country;
            this.address.City = this.data.City;
            this.address.Detail = this.data.Detail;
        }
        // console.log(this.address);
        this.CountryFun();
    },
    methods: {
        CountryFun() {
            this.$http.getAreaByPid().then((res) => {
                let data = res.data;
                if (data.length > 0) {
                    //   let nameEn = data[0].nameEn;
                    //   this.address.Country = nameEn;
                    data.map((o) => {
                        o.value = o.areaId;
                        o.label = o.nameEn;
                    });
                    this.CountryList = data;
                    let areaId = data[0].areaId;
                    if (this.address.Country) {
                        const [country] = data.filter(
                            (items) => items.nameEn == this.address.Country
                        );
                        areaId = country.areaId;
                    }
                    this.CityFun(areaId);
                } else {
                    this.CountryList = [];
                }
            });
        },

        CityFun(areaId) {
            this.$http
                .getAreaByPid({
                    pid: areaId,
                })
                .then((res) => {
                    let data = res.data;
                    if (data.length > 0) {
                        // let nameEn = data[0].nameEn;
                        // this.address.City = nameEn;
                        data.map((o) => {
                            o.value = o.areaId;
                            o.label = o.nameEn;
                        });
                        this.CityList = data;
                        let areaId = data[0].areaId;
                        if (this.address.City) {
                            const [city] = data.filter(
                                (items) => items.nameEn == this.address.City
                            );
                            areaId = city.areaId;
                        }
                        this.DetailFun(areaId);
                        this.$emit("change", this.address);
                    } else {
                        this.CityList = [];
                        this.address.City = "";
                        this.$emit("change", this.address);
                    }
                });
        },

        DetailFun(areaId) {
            this.$http
                .getAreaByPid({
                    pid: areaId,
                })
                .then((res) => {
                    let data = res.data;
                    if (data.length > 0) {
                        // let nameEn = data[0].nameEn;
                        // this.address.Detail = nameEn;
                        data.map((o) => {
                            o.value = o.areaId;
                            o.label = o.nameEn;
                        });
                        this.DetailList = data;
                        this.$emit("change", this.address);
                    } else {
                        this.address.Detail = "";
                        this.DetailList = [];
                        this.$emit("change", this.address);
                    }
                });
        },
        changeSelect(e, type = "") {
            if (type == "Country") {
                this.CountryList.forEach((os) => {
                    if (os.label == e) {
                        this.CityFun(os.areaId);
                    }
                });
            } else if (type == "City") {
                this.CityList.forEach((os) => {
                    if (os.label == e) {
                        this.DetailFun(os.areaId);
                    }
                });
            } else {
                this.$emit("change", this.address);
            }
        },
    },
};
</script>

<style></style>
