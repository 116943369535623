<template>
    <div class="file flex-start">
        <div>
            <div class="text-normal">Choose File</div>
            <input
                type="file"
                ref="InputFile"
                name="files"
                :disabled="disabled"
                @change="uploadPic"
            />
        </div>
        <div
            class="filetxt"
            :class="{ 'filetext-color': file != 'No file chosen' }"
        >
            <div @click="onClickPreview">{{ file }}</div>
            <img
                class="del"
                src="../assets/del.png"
                alt=""
                @click="onClickDel"
                v-if="filePath && !disabled"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "Information",
    props: {
        filename: {
            type: String,
            default: "",
        },
        path: {
            type: String,
            default: "",
        },
        index: {
            type: Number,
            default: -1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            file: "No file chosen",
            filePath: "",
        };
    },
    created() {
        if (this.filename) {
            this.file = this.filename;
        }
        if (this.path) {
            this.filePath = this.path;
        }
    },
    watch: {
        filename() {
            if (!this.filename) {
                this.file = "No file chosen";
                this.filePath = "";
            }
        },
    },
    methods: {
        onClickPreview() {
            window.open(this.filePath);
        },
        uploadPic() {
            let files = this.$refs.InputFile.files[0];
            const filename = files.name;
            let fileFormData = new FormData();
            fileFormData.append("file", files);
            fileFormData.append("fileName", filename);
            this.$http.uploadFile(fileFormData).then((res) => {
                if (res.code == 200) {
                    //   this.file = res.data;
                    this.file = filename;
                    this.filePath = res.data;
                    const params = {
                        filePath: res.data,
                        fileName: filename,
                    };
                    this.$emit("choose", params);
                    this.$message({
                        message: "Upload succeeded",
                        type: "success",
                    });
                }
            });
        },
        onClickDel() {
            this.$confirm("Confirm the deletion").then(() => {
                this.$emit("delete", this.index);
            });
        },
    },
};
</script>

<style>
.filetxt {
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.del {
    width: 14px;
    height: 14px;
}
.filetext-color {
    color: #606266 !important;
}
</style>
