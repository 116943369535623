<template>
    <div class="containerTab">
        <div class="scrollbar" ref="scrollbar" id="scrollbar">
            <div class="tab flex-center" ref="scrollTab" style="width: 100%">
                <div
                    class="flex-center"
                    style="flex-shrink: 0"
                    v-for="(item, i) in list"
                    :key="i"
                    :id="'left_' + i"
                    @click="changeTab(i)"
                >
                    <div :class="i == curTab ? 'tabList curBottom' : 'tabList'">
                        <div class="esp">
                            <span>{{ item.name }}</span>
                        </div>
                        <div v-if="!disabled">
                            <i
                                v-if="showAdd"
                                class="el-icon-close"
                                style="color: #919797"
                                @click.stop="delTab(i)"
                            ></i>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>
                <div class="add" @click="addTab" v-if="showAdd && !disabled">
                    <i
                        class="el-icon-plus"
                        style="color: #919797; font-size: 28px; cursor: pointer"
                    ></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tabs",
    props: {
        curTab: {
            type: Number,
            default: 0,
        },
        showAdd: {
            type: Boolean,
            default: true,
        },
        list: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            leftLen: 0,
        };
    },
    methods: {
        changeTab(i) {
            this.$refs.scrollbar.scrollLeft = Math.max((i - 2) * 200, 0);
            this.$emit("change", i);
        },
        addTab() {
            let len = this.list.length;
            this.list.push({
                name: "Unnamed " + (len + 1),
            });
            this.$emit("change", len);
            this.$emit("changeTab", this.list, "add", len);
        },
        delTab(i) {
            if (this.list.length > 1) {
                this.list.splice(i, 1);
                this.$emit("change", this.list.length - 1);
                this.$emit(
                    "changeTab",
                    this.list,
                    "del",
                    Math.max(i - 1, 0),
                    i
                );
            } else {
                this.$message({
                    message: "Fill in at least one",
                    type: "success",
                });
            }
        },
    },
};
</script>

<style>
.containerTab ::-webkit-scrollbar {
    width: 5px !important;
}

.containerTab ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px;
    background-color: rgb(46, 201, 195, 0.3);
    border-radius: 5px;
}

.containerTab .scrollbar {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 59px;
}
</style>
