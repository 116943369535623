<template>
    <div class="container">
        <div class="inputList">
            <div class="company">Product Scope</div>
            <div class="flex-center">
                <div class="text-bold text-underline flex-end text-center">
                    <div>% of turnover</div>
                </div>
                <div class="text-bold text-underline flex-end text-center">
                    <div>% of turnover</div>
                </div>
            </div>
            <div
                class="flex-start"
                style="flex-wrap: wrap; cursor: not-allowed"
                v-if="type == 'Echo'"
            >
                <div
                    class="checkboxs chek1"
                    v-for="(item, index) in dataList"
                    :key="index"
                >
                    <div class="parent flex-between">
                        <el-checkbox
                            disabled
                            :indeterminate="item.isIndeterminate"
                            v-model="item.checked"
                        >
                            {{ item.dictName }}
                        </el-checkbox>
                        <div
                            class="tag flex-center flex-shrink"
                            v-if="item.checked"
                        >
                            <div>
                                <input
                                    type="number"
                                    disabled
                                    v-model="item.turnover"
                                />
                            </div>
                            <div class="text-normal">%</div>
                        </div>
                    </div>
                    <div class="children">
                        <div
                            class="checkboxList flex-between"
                            v-for="(items, s) in item.childArray"
                            :key="s"
                        >
                            <el-checkbox
                                style="width: 210px"
                                disabled
                                v-model="items.checked"
                                :label="items.dictName"
                            >
                            </el-checkbox>
                            <div
                                class="tag flex-center flex-shrink"
                                v-if="items.checked"
                            >
                                <div>
                                    <input
                                        type="number"
                                        disabled
                                        v-model="items.turnover"
                                    />
                                </div>
                                <div class="text-normal">%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-start" style="flex-wrap: wrap" v-else>
                <div
                    class="checkboxs chek1"
                    v-for="(item, index) in dataList"
                    :key="index"
                >
                    <div class="parent flex-between">
                        <el-checkbox
                            :indeterminate="item.isIndeterminate"
                            v-model="item.checked"
                            @change="handleCheckAllChange(index, $event)"
                            :disabled="disabled"
                        >
                            {{ item.dictName }}
                        </el-checkbox>
                        <div
                            class="tag flex-center flex-shrink"
                            v-if="item.checked"
                        >
                            <div>
                                <input
                                    type="number"
                                    v-model="item.turnover"
                                    @change="filterList"
                                    :disabled="disabled"
                                />
                            </div>
                            <div class="text-normal">%</div>
                        </div>
                    </div>
                    <div class="children">
                        <div
                            class="checkboxList flex-between"
                            v-for="(items, s) in item.childArray"
                            :key="s"
                        >
                            <el-checkbox
                                style="width: 210px"
                                v-model="items.checked"
                                :label="items.dictName"
                                :disabled="disabled"
                                @change="
                                    handleCheckedCitiesChange(
                                        index,
                                        items.id,
                                        $event
                                    )
                                "
                            >
                            </el-checkbox>
                            <div
                                class="tag flex-center flex-shrink"
                                v-if="items.checked"
                            >
                                <div>
                                    <input
                                        type="number"
                                        v-model="items.turnover"
                                        :disabled="disabled"
                                        @change="filterList"
                                    />
                                </div>
                                <div class="text-normal">%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Scope",
    props: {
        type: {
            type: String,
            default: "",
        },
        list: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dataList: [],
        };
    },
    created() {
        let productList = JSON.parse(localStorage.getItem("product_scope"));
        // if (this.type == "Echo") {
        //   this.dataList = this.formatEcho(productList);
        // } else {
        //   this.dataList = this.format(productList);
        // }
        if (productList) {
            this.dataList = this.formatEcho(productList);
        } else {
            this.dataList = this.format(productList);
        }
    },
    methods: {
        // 全选反选的多选框
        handleCheckAllChange(index, e) {
            this.dataList[index].checked = e;
            if (e == false) this.dataList[index].isIndeterminate = false;
            var childrenArray = this.dataList[index].childArray;
            if (childrenArray) {
                for (var i = 0, len = childrenArray.length; i < len; i++)
                    childrenArray[i].checked = e;
            }
            this.filterList();
        },

        handleCheckedCitiesChange(index, sobId, e) {
            var childrenArray = this.dataList[index].childArray;
            var tickCount = 0,
                unTickCount = 0,
                len = childrenArray.length;
            for (var i = 0; i < len; i++) {
                if (sobId == childrenArray[i].sobId)
                    childrenArray[i].checked = e;
                if (childrenArray[i].checked == true) tickCount++;
                if (childrenArray[i].checked == false) unTickCount++;
            }
            if (tickCount == len) {
                this.dataList[index].checked = true;
                this.dataList[index].isIndeterminate = false;
            } else if (unTickCount == len) {
                this.dataList[index].checked = false;
                this.dataList[index].isIndeterminate = false;
            } else {
                this.dataList[index].checked = false;
                this.dataList[index].isIndeterminate = true;
            }

            this.filterList();
        },

        filterList() {
            let list = [];
            this.dataList.forEach((vs) => {
                if (vs.checked) {
                    list.push({
                        dictId: vs.id,
                        dictName: vs.dictName,
                        trunover: vs.turnover,
                    });
                }
                vs.childArray.forEach((ks) => {
                    if (ks.checked) {
                        list.push({
                            dictId: ks.id,
                            dictName: ks.dictName,
                            trunover: ks.turnover,
                        });
                    }
                });
            });
            this.$emit("change", list);
        },

        // 给返回的数据加是否选中的属性
        format(items) {
            return items.map((item) => {
                const result = {
                    ...item,
                    turnover: null,
                    checked: false,
                    isIndeterminate: false,
                };
                if (item.childArray) {
                    result.childArray = this.format(item.childArray);
                }
                return result;
            });
        },

        // 回显
        formatEcho(productList) {
            productList.forEach((s) => {
                s.turnover = "";
                s.checked = false;
                s.isIndeterminate = false;
                this.list.forEach((d) => {
                    if (s.id == d.dictId) {
                        s.checked = true;
                        s.turnover = d.trunover;
                        s.exclusivity = d.exclusivity;
                    }
                });

                let list = 0;
                s.childArray.forEach((k) => {
                    k.turnover = "";
                    k.checked = false;
                    k.isIndeterminate = false;
                    this.list.forEach((f) => {
                        if (k.id == f.dictId) {
                            k.checked = true;
                            k.turnover = f.trunover;
                            list++;
                        }
                    });
                });
                if (s.childArray.length > list) {
                    s.isIndeterminate = true;
                }
            });
            return productList;
        },
    },
};
</script>

<style></style>
