export default {
	"attachmentList": [],
	"businessAddressCity": "",
	"businessAddressCountry": "",
	"businessAddressDetail": "",
	"businessAddressState": "",
	"businessLicenseNo": "",
	"businessScaleList": [{
		"aliasName": "",
		"companyId": 0,
		"dataStatus": 0,
		"isEnable": 0,
		"lastYear": "",
		"previousYear": "",
		"thisYear": ""
	}, {
		"aliasName": "",
		"companyId": 0,
		"dataStatus": 0,
		"isEnable": 0,
		"lastYear": "",
		"previousYear": "",
		"thisYear": ""
	}],
	"businessType": "",
	"companyInfo": "",
	"companyName": "",
	"contact": {
		"companyId": 0,
		"contactName": "",
		"contactSex": "",
		"emailAddress": "",
		"mobileNumber": "",
		"position": ""
	},
	"dateEstablished": "",
	"ownershipStructure": "",
	"postCode": "",
	"telNo": "",
	"website": ""
}
