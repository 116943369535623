<template>
    <div class="container">
        <div
            class="inputList"
            v-if="type == 'Echo'"
            style="cursor: not-allowed"
        >
            <div class="company">Factory Information</div>
            <div
                class="flex-between mb-2"
                v-for="(item, i) in infoList"
                :key="i"
            >
                <div class="text-normal">
                    <el-checkbox disabled v-model="item.IsCheck">
                        {{ item.name }}
                    </el-checkbox>
                </div>
                <div class="file flex-start echoPath">
                    {{ item.filePath.slice(40) }}
                </div>
            </div>
        </div>
        <div class="inputList" v-else>
            <div class="company">Accreditation</div>
            <div class="company-desc">
                Please click the Checkbox, and then upload the document
                accordingly.
            </div>
            <div
                class="flex-between mb-2"
                v-for="(item, i) in infoList"
                :key="i"
            >
                <div class="text-normal">
                    <el-checkbox
                        v-model="item.IsCheck"
                        :disabled="disabled"
                        @change="changeCheckbox($event, item, 3)"
                    >
                        {{ item.name }}
                    </el-checkbox>
                </div>
                <Choose
                    v-if="item.IsCheck"
                    :filename="item.fileName"
                    :path="item.filePath"
                    :index="i"
                    :disabled="disabled || !item.IsCheck"
                    @choose="chooseFile($event, item)"
                    @delete="onChangeDelete"
                ></Choose>
            </div>
        </div>
    </div>
</template>

<script>
import Choose from "../components/Choose.vue";
export default {
    name: "InfoFile",
    components: {
        Choose,
    },
    props: {
        type: {
            type: String,
            default: "",
        },
        list: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            infoList: [
                {
                    name: "lSO14001",
                    IsCheck: false,
                    filePath: "",
                    fileName: "",
                },
                {
                    name: "lSO9001",
                    IsCheck: false,
                    filePath: "",
                    fileName: "",
                },
                {
                    name: "SA8000",
                    IsCheck: false,
                    filePath: "",
                    fileName: "",
                },
                {
                    name: "BSCI",
                    IsCheck: false,
                    filePath: "",
                    fileName: "",
                },
                {
                    name: "SEDEX",
                    IsCheck: false,
                    filePath: "",
                    fileName: "",
                },
                {
                    name: "FSC Certification",
                    IsCheck: false,
                    filePath: "",
                    fileName: "",
                },
            ],
        };
    },
    created() {
        // if (this.type == "Echo") {
        this.infoList.forEach((os) => {
            this.list.forEach((ks) => {
                if (ks.name == os.name) {
                    os.IsCheck = true;
                    os.filePath = ks.filePath;
                    os.fileName = ks.fileName;
                }
            });
        });
        // }
    },
    methods: {
        chooseFile(e, item) {
            item.filePath = e.filePath;
            item.fileName = e.fileName;
            let accreditationList = [];
            let list = this.infoList.filter((k) => k.IsCheck == true);
            list.forEach((l) => {
                accreditationList.push({
                    filePath: l.filePath,
                    name: l.name,
                    fileName: l.fileName,
                });
            });
            this.$emit("change", accreditationList);
        },
        changeCheckbox(e, item) {
            item.IsCheck = e;
            let accreditationList = [];
            let list = this.infoList.filter((k) => k.IsCheck == true);
            list.forEach((l) => {
                accreditationList.push({
                    filePath: l.filePath,
                    name: l.name,
                    fileName: l.fileName,
                });
            });
            this.$emit("change", accreditationList);
        },
        onChangeDelete(index) {
            let accreditationList = [];
            const temp = this.infoList[index];
            temp.fileName = "";
            temp.filePath = "";
            this.$set(this.infoList, index, temp);
            let list = this.infoList.filter((k) => k.IsCheck == true);
            list.forEach((l) => {
                accreditationList.push({
                    filePath: l.filePath,
                    name: l.name,
                    fileName: l.fileName,
                });
            });
            this.$emit("change", accreditationList);
        },
    },
};
</script>

<style scoped>
.echoPath {
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    color: #919797;
    padding-left: 12px;
    padding-top: 0;
    line-height: 40px;
}
.company-desc {
    margin-bottom: 20px;
    color: #fa2a2d;
}
</style>
