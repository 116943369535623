export default {
	"accreditationList": [],
	"attachmentList": [],
	"businessScaleList": [{
		"aliasName": "",
		"lastYear": "",
		"previousYear": "",
		"thisYear": ""
	}, {
		"aliasName": "",
		"lastYear": "",
		"previousYear": "",
		"thisYear": ""
	}],
	"csr": "",
	"dateEstablished": "",
	"email": "",
	"exportPort": "",
	"factoryAddress": "",
	"factoryName": "",
	"keymarketCustomerList": [],
	"loadingPortLocationArea": "",
	"loadingPortLocationCity": "",
	"loadingPortLocationCountry": "",
	"ownershipStructure": "",
	"productScopeList": [],
	"telNo": ""
}
